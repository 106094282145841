import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "expired flex-center"
}
const _hoisted_2 = {
  key: 1,
  class: "expired flex-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.link)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "This link has expired. A new link has been emailed to the address associated with this order."))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "This has experience has already passed. Waivers are not being accepted anymore for this experience."))
}