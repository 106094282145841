
import { kioskModule } from '@/store/kiosk.vuex-module';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'ExpiredOrderView',
	components: {},
})
export default class ExpiredOrderView extends Vue {
	created() {
		kioskModule.resetSession();
	}
	get link() {
		return this.$route.query.link === 'true';
	}
}
